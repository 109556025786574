// Import React Component
import React, { useContext } from "react";

// Import Link Component
import { Link } from "react-router-dom";

// Import Translation Module
import { useTranslation } from "react-i18next";

// Import Context
import { MenuOpened } from "../../.../../../MenuOpenedContext";

function LinkComponent({ url, link, children, text }) {
  const { t } = useTranslation(link, {
    keyPrefix: "links",
  });

  const [isOpened, setIsOpened] = useContext(MenuOpened);

  const menuHandlerResponsiveMenu = () => {
    if (isOpened) {
      setIsOpened(!isOpened);
    }
  };

  return (
    <li>
      <Link
        style={{ fontSize: "13px" }}
        onClick={() => menuHandlerResponsiveMenu()}
        to={url}
      >
        {t(`${text}`)}
      </Link>
      {children}
    </li>
  );
}

export default LinkComponent;
