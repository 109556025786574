import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Careers.style.scss";
import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";

function CareersJoin() {
  const { t } = useTranslation("career");

  return (
    <div className="careersJoin">
      <div className="join-wrapper">
        <Flip>
          <h1>{t("join-title")}</h1>
        </Flip>
        <div>
          <Link to="#" className="carousel-button mr-3">
            <Fade left>{t("join-button-1")}</Fade>
          </Link>
          <Link to="#" className="carousel-button">
            <Fade right>{t("contact")}</Fade>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default CareersJoin;
