import React from "react";
import "./UsageAreas.style.scss";
import { useTranslation } from "react-i18next";
import UsageNavLink from "./usageNavLink";

const UsageAreaNav = () => {
  const { t } = useTranslation("usage-areas");
  const links = [
    {
      title: t("house"),
      path: "/usageareas/house",
    },
    {
      title: t("shopping-mall"),
      path: "/usageareas/shopping-mall",
    },
    {
      title: t("hotels"),
      path: "/usageareas/Hotels",
    },
    {
      title: t("health"),
      path: "/usageareas/health",
    },
    {
      title: t("stadium"),
      path: "/usageareas/stadium",
    },
    {
      title: t("factory"),
      path: "/usageareas/restaurants",
    },
    {
      title: t("car-park"),
      path: "/usageareas/car-park",
    },
    {
      title: t("panoramic"),
      path: "/usageareas/panoramic",
    },
    {
      title: t("solutions"),
      path: "/usageareas/solutions",
    },
  ];

  return (
    <div className="usage-area-nav">
      <div className="wrapper">
        {links.map((link) => (
          <UsageNavLink title={link.title} path={link.path} key={link.title} />
        ))}
      </div>
    </div>
  );
};

export default UsageAreaNav;
