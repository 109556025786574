import React from "react";
// import { Link } from "react-router-dom";
import Section4 from "./Section-4";
import { useTranslation } from "react-i18next";
import AboutImage1 from "../../../assets/img/Otis_OurCompany_newsroom_link_image.jpg";
import AboutImage2 from "../../../assets/img/Otis_OurCompany_careers_bottom_page_link_image.jpg";

function Section_3() {
  const { t } = useTranslation("about-us");

  const object = [
    {
      title: t("news"),
      src: AboutImage1,
      path: "/news",
    },
    {
      title: t("career"),
      src: AboutImage2,
      path: "/career",
    },
  ];
  return (
    <div className="container">
      <section className="aboutus-section-3">
        {object.map((img) => (
          <Section4
            src={img.src}
            path={img.path}
            title={img.title}
            key={img.title}
          />
        ))}
      </section>
    </div>
  );
}

export default Section_3;
