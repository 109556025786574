import React from "react";
import img1 from "./images/tools_OB.jpg";
import img2 from "./images/contact_OB.jpg";
import { useTranslation } from "react-i18next";
import JumpingCard from "./JumpingCard";

const Card2 = () => {
  const { t } = useTranslation("modernization");
  const obj = [
    {
      title: t("modernitation-card-1-title"),
      src: img1,
      path: "#",
    },
    {
      title: t("modernitation-card-2-title"),
      src: img2,
      path: "#",
    },
  ];

  return (
    <div className="container">
      <div className="cardJump-wrapper">
        {obj.map((img) => (
          <JumpingCard
            src={img.src}
            path={img.path}
            title={img.title}
            key={img.title}
          />
        ))}
      </div>
    </div>
  );
};

export default Card2;
