import React from "react";

// Reactstrap Components
import { Container, Row, Col } from "reactstrap";

// Local Components
import LinkComponent from "../LinkComponent";
import SocialComponent from "../SocialComponent";
import LogoLinkComponent from "../LogoLinkComponent";

// Icons Components
import {
  HiOutlineLocationMarker,
  HiOutlinePhone,
  HiOutlineMail,
} from "react-icons/hi";

import { FiInstagram, FiFacebook, FiLinkedin } from "react-icons/fi";
import { FaTelegramPlane } from "react-icons/fa";

// Import Translation Module
import { useTranslation } from "react-i18next";

// Styles
import "./Footer.style.scss";

// Footer Component
function FooterComponent() {
  const { t } = useTranslation("footer");

  return (
    <footer>
      <div className="footer-main">
        <Container>
          <Row
            className="d-flex flex-lg-row  flex-column align-items-center align-items-lg-start m-0"
            style={{
              width: "100%",
            }}
          >
            <Col
              sm="12"
              md="6"
              lg="3"
              className="info d-flex flex-column align-items-center text-center text-lg-start align-items-lg-start mb-lg-0 mb-5"
            >
              <LogoLinkComponent />
              <p>{t("main")}</p>
              <p>
                {t("working-hours")}
                <br />
                {t("working-hour")}
              </p>
            </Col>
            <Col
              sm="12"
              lg="3"
              className="services text-center text-lg-start mb-lg-0 mb-5"
            >
              <h4>{t("quick-links")}</h4>
              <ul>
                <LinkComponent text="news" url="news" link="footer" />
                <LinkComponent text="rd-center" url="rdcenter" link="footer" />
                <LinkComponent text="career" url="career" link="footer" />
                <LinkComponent text="contacts" url="contact" link="footer" />
              </ul>
            </Col>
            <Col
              sm="12"
              lg="3"
              className="quick-links text-center text-lg-start mb-lg-0 mb-5"
            >
              <h4>{t("pages")}</h4>
              <ul>
                <LinkComponent text="about" url="company" link="footer" />
                <LinkComponent text="products" url="products" link="footer" />
                <LinkComponent
                  text="usageareas"
                  url="usageareas"
                  link="footer"
                />
                <LinkComponent
                  text="references"
                  url="references"
                  link="footer"
                />
                <LinkComponent
                  text="servicemaintenance"
                  url="technicalservise"
                  link="footer"
                />
              </ul>
            </Col>
            <Col
              sm="12"
              lg="3"
              className="contact d-flex flex-column align-items-center text-center align-items-lg-start text-lg-start mb-lg-0 mb-5"
            >
              <h4>{t("contact")}</h4>
              <div className="location d-flex gap-2 flex-lg-row flex-column align-items-lg-start align-items-center mb-3 mb-lg-0">
                <div className="">
                  <HiOutlineLocationMarker />
                </div>
                <div className="phone-num d-flex flex-column align-items-center align-items-lg-start mb-3 mb-lg-0">
                  <span>{t("company-name")}</span>
                  <span>{t("street")}</span>
                  <span>{t("country")}</span>
                </div>
              </div>
              <div className="phone-numbers mt-4  gap-2 d-flex flex-lg-row flex-column align-items-lg-start align-items-center mb-3 mb-lg-0">
                <div>
                  <HiOutlinePhone />
                </div>
                <div className="phone-num d-flex flex-column align-items-start">
                  <a href="tel:+998 78 147 40 30">
                    <span>+998 78 147 40 30</span>
                  </a>
                  <a href="tel:+998 90 045 40 30">
                    <span>+998 90 045 40 30</span>
                  </a>
                  <a href="tel:+998 90 043 40 30">
                    <span>+998 90 043 40 30</span>
                  </a>
                </div>
              </div>
              <div className="emails d-flex gap-2 mt-4 flex-lg-row flex-column align-items-lg-start align-items-center">
                <div>
                  <HiOutlineMail />
                </div>
                <div className="phone-num d-flex flex-column align-items-center align-items-lg-start">
                  <a href="mailto:info@betaliftasia.com">
                    <span>info@betaliftasia.com</span>
                  </a>
                  <a href="ramazan@betaliftasia.com">
                    <span>ramazan@betaliftasia.com</span>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="footer-bottom text-center w-100">
        <ul className="d-flex align-items-center justify-content-center gap-3">
          <SocialComponent url="https://instagram.com/" Icon={FiInstagram} />
          <SocialComponent url="https://facebook.com/" Icon={FiFacebook} />
          <SocialComponent
            url="https://web.telegram.org/"
            Icon={FaTelegramPlane}
          />
          <SocialComponent url="https://linkedin.com/" Icon={FiLinkedin} />
        </ul>
        <p className="mt-3">
          Copyright © BETALIFTASIA, 2021 |{" "}
          <a className="text-white" href="https://albison.uz">
            by "albison"
          </a>
        </p>
      </div>
    </footer>
  );
}

export default FooterComponent;
