import React from "react";
import { useTranslation } from "react-i18next";
import "./news.style.scss";
import Roll from "react-reveal/Roll";

function ForMedia() {
  const { t } = useTranslation("news");

  return (
    <div className="container">
      <section className="ForMedia d-flex justify-content-center">
        <div>
          <Roll top>
            <h1>{t("media-title")}</h1>
          </Roll>
        </div>
      </section>
    </div>
  );
}

export default ForMedia;
