import React from "react";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

function Text() {
  const { t } = useTranslation("usage-areas");
  return (
    <div className="container">
      <div className="text-wrapper">
        <Fade bottom>
          <h1 className="title">{t("rdcenter-title")}</h1>
        </Fade>

        <Fade bottom>
          <p>{t("rdcenter-text-1")}</p>
        </Fade>

        <br />
        <br />
        <Fade bottom>
          <p>{t("rdcenter-text-2")}</p>
        </Fade>
        <br />
        <br />
        <Fade bottom>
          <p>{t("rdcenter-text-3")}</p>
        </Fade>
      </div>
    </div>
  );
}

export default Text;
