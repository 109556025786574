import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import Fade from "react-reveal/Fade";
import ShoppingMallShowcase from "./ShoppingMallShowcase";

const ShoppingMall = () => {
  const { t } = useTranslation("usage-areas");
  return (
    <>
      <ShoppingMallShowcase />
      <div className="desk">
        <div className=" section">
          <div className="container fullge">
            <div className="Section-full">
              <Fade bottom>
                <h4
                  style={{
                    display: "inline-block",
                    fontWeight: 900,
                    color: "#000",
                  }}
                >
                  {t("shopping-title")}
                </h4>
              </Fade>
              <div className="section-title">
                <Fade bottom>
                  <p>{t("shopping-mall-text")}</p>
                </Fade>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShoppingMall;
