import React from "react";
import "./Post.style.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import i18next from "i18next";

function Post({ reports }) {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation();
  return (
    <div className="card-wrapper">
      <div className="card-img-wrapper">
        {reports.images && (
          <img className="singlePostImg" src={reports?.images[0]?.src} alt="" />
        )}
      </div>
      <span className="post-date">
        {new Date(reports?.date).toLocaleString(locale)}
      </span>
      <h4>{reports?.title?.[locale]}</h4>

      <p className="post-description">{reports?.description?.[locale]}</p>

      <Link className="button-link" to={`/post/${reports?._id}`}>
        <button className="button-dark">{t("LEARN MORE")}</button>
      </Link>
    </div>
  );
}

export default Post;
