import React from "react";
import Fade from "react-reveal/Fade";
import { useTranslation } from "react-i18next";

const HealthTextPage = () => {
  const { t } = useTranslation("usage-areas");
  return (
    <div className="health-text-page">
      <div className="container">
        <Fade bottom>
          <b style={{ display: "inline-block" }}>{t("health-title")}</b>
        </Fade>

        <Fade bottom>
          <p className="health-text">{t("health-text")}</p>
        </Fade>
      </div>
    </div>
  );
};

export default HealthTextPage;
