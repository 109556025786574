import React from "react";
import Showcase from "./CareersShowcase";
import "./Careers.style.scss";
import CareersTextPage from "./CareersTextPage";
import Apartment from "./Apartment";
import Galleries from "./Galleries";
import LookFor from "./lookFor";
import CareersJoin from "./CareersJoin";

function Careers() {
  return (
    <div>
      <Showcase />
      <CareersTextPage />
      <Apartment />
      <Galleries />
      <LookFor />
      <CareersJoin />
    </div>
  );
}

export default Careers;
