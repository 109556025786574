import React from "react";
import {
  FaFacebookF, FaTelegramPlane,
  // FaAngleDoubleRight,
  // FaTelegram
} from "react-icons/fa";
import { BsFillTelephoneFill } from "react-icons/bs";
import { ImLocation } from "react-icons/im";
import { FaFax } from "react-icons/fa";
import { FaEnvelope } from "react-icons/fa";
import { AiOutlineInstagram } from "react-icons/ai";
import { VscTwitter } from "react-icons/vsc";
import { TiSocialYoutubeCircular } from "react-icons/ti";
import { FaWhatsapp } from "react-icons/fa";
import { SiPinterest } from "react-icons/si";
import { AiFillLinkedin } from "react-icons/ai";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Roll from "react-reveal/Roll";
import Fade from "react-reveal/Fade";
import ContactShowcase from "./Showcase";

import "./ContactPage.style.scss";
function ContactPage() {
  const { t } = useTranslation("contact");
  return (
    <>
      <ContactShowcase />
      <div className="container py-5">
        <div className="row  m-0">
          <div className="col-lg-6">
            <Roll top>
              <div className="contact-title">{t("left-title")}</div>
            </Roll>

            <ul className="contact-list">
              <li>
                <ImLocation />
                {t("address")}
              </li>

              <li>
              <FaFax className="icon" />
                <Link to=" tel:+998 78 147 40 30 ">+998 78 147 40 30 </Link>
              </li>
              <li>
                <BsFillTelephoneFill className="icon" />
                <Link to=" tel:+998 90 045 40 30">+998 90 045 40 30</Link>
              </li>

              <li>
                <BsFillTelephoneFill className="icon" />
                <Link to="tel:+998 90 043 40 30 ">+998 90 043 40 30 </Link>
              </li>
              <li>
                <FaTelegramPlane className="icon" />
                <Link to="https://t.me/betaliftasia">+998 90 047 40 30 </Link>
              </li>

              <li>
                <FaEnvelope className="icon" />
                <Link to="mailinfo:info@betaliftasia.com">info@betaliftasia.com</Link>
              </li>
              <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br /> <br />
            </ul> 
           

            <div className="link-icon d-flex flex-wrap">
              <div
                className="iconInformation d-flex justify-content-center align-items-center rounded-circle border me-3 border-dark"
                style={{ width: "45px", height: "45px" }}
              >
                <Link to="https://www.facebook.com/">
                  <FaFacebookF />
                </Link>
              </div>
              <div
                className="iconInformation d-flex justify-content-center align-items-center rounded-circle border me-3 border-dark"
                style={{ width: "45px", height: "45px" }}
              >
                <Link to="https://www.instagram.com/?hl=ru">
                  <AiOutlineInstagram />
                </Link>
              </div>
          
              <div
                className=" iconInformation d-flex justify-content-center align-items-center rounded-circle border me-3 border-dark"
                style={{ width: "45px" }}
              >
                <Link to="https://www.youtube.com/">
                  <TiSocialYoutubeCircular />
                </Link>
              </div>
        
       
              <div
                className="iconInformation d-flex justify-content-center align-items-center rounded-circle border me-3 border-dark"
                style={{ width: "45px", height: "45px" }}
              >
                <Link to="https://www.linkedin.com/home/?originalSubdomain=ru">
                  <AiFillLinkedin />
                </Link>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-5 m-lg-0">
            <Roll top>
              <div className="contact-title">{t("contact-form")}</div>
            </Roll>
            <Fade right>
              <form
                className="contact-form"
                action="https://formsubmit.co/ibrohim97uz@gmail.com"
                method="POST"
              >
                <div className="contact-1 ">
                  <label className="fw-bold">{t("name-surname")}</label>
                  <div className="form-title">
                    <input
                      id="surname"
                      name="name-surname"
                      type="text"
                      maxLength="50"
                      placeholder={t("name-surname")}
                      required
                      className="form-control"
                    />
                  </div>
                  <label className="fw-bold">{t("phone")}</label>

                  <div className="form-title">
                    <input
                      id="number"
                      name="phone-number"
                      type="text"
                      maxLength="20"
                      placeholder={t("phone")}
                      required
                      className="form-control"
                    />
                  </div>
                  <label className="fw-bold">{t("email")}</label>

                  <div className="form-title">
                    <input
                      id="addre"
                      name="e-mail-address"
                      type="text"
                      maxLength="50"
                      placeholder={t("email")}
                      required
                      className="form-control"
                    />
                  </div>
                  <label className="fw-bold">{t("message")}</label>
                  <div className="form-group">
                    <textarea
                      name="your-massage"
                      type="textarea"
                      maxLength="500"
                      required
                      className="form-control"
                    ></textarea>
                  </div>
                  <div className="batr">
                    <button type="submit">{t("submit")}</button>
                  </div>
                </div>
              </form>
            </Fade>
          </div>
        </div>
      </div>
      <div className="section-map">
        <iframe
          title="location"
          className="w-100"
          height="500"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d23972.972453558315!2d69.32137459442023!3d41.317095763653306!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38aef4e61907153b%3A0xef6dd71b06d2dd7c!2sSalom+Telecom!5e0!3m2!1sru!2s!4v1503429233146"
        ></iframe>
      </div>
    </>
  );
}

export default ContactPage;
