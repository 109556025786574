import React from "react";
import HealthShowcase from "./HealthShowcase";
import HealthTextPage from "./HealthTextPage";

const Health = () => {
  return (
    <div>
      <HealthShowcase />
      <HealthTextPage />
    </div>
  );
};

export default Health;
