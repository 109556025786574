import React from "react";

import NewsLastPage from "./News_LastPage";
import { useTranslation } from "react-i18next";
import AboutImage1 from "../img/mediacontact_OB.jpg";
import AboutImage2 from "../img/mediaassets_OB.jpg";

function NewsLastPageImg() {
  const { t } = useTranslation("news");

  const object = [
    {
      title: t("media-card-1-title"),
      src: AboutImage1,
      path: "/contact",
    },
    {
      title: t("media-card-2-title"),
      src: AboutImage2,
      path: "/contact",
    },
  ];
  return (
    <div>
      <section className="News_LastPage_Imgg">
        {object.map((img) => (
          <NewsLastPage
            src={img.src}
            path={img.path}
            title={img.title}
            key={img.title}
          />
        ))}
      </section>
    </div>
  );
}

export default NewsLastPageImg;
