import React from "react";

import "./showcase.scss";

const HouseShowcase = ({ title, text, className }) => {
  return (
    <div className={`house-showcase ${className}`}>
      <div className="text-wrapper">
        <h1 className="title">{title}</h1>
        <br />
        <p>{text}</p>
      </div>
    </div>
  );
};

export default HouseShowcase;
