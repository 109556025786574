import React from "react";

import NavbarComponent from "./components/Navbar/Navbar";
import FooterComponent from "./components/Footer/Footer";

function Layout({ children }) {
  return (
    <>
      <NavbarComponent />
      {children}
      <FooterComponent />
    </>
  );
}

export default Layout;
