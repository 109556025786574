import React from "react";
import Card from "./Card";
import img1 from "./images/card-1.jpg";
import img2 from "./images/card-2.jpg";
import img3 from "./images/card-3.jpg";
import img4 from "./images/card-4.jpg";
import img5 from "./images/card-5.jpg";
import { useTranslation } from "react-i18next";
import Pulse from "react-reveal/Pulse";

const Cards = () => {
  const { t } = useTranslation("modernization");
  const cardImages = [
    {
      name: "img - 1",
      path: img1,
      title: t("modernization-card-1-suptitle"),
      subtitle: t("modernization-card-1-title"),
    },
    {
      name: "img - 2",
      path: img2,
      title: t("modernization-card-2-suptitle"),
      subtitle: t("modernization-card-2-title"),
    },
    {
      name: "img - 3",
      path: img3,
      title: t("modernization-card-3-suptitle"),
      subtitle: t("modernization-card-3-title"),
    },
    {
      name: "img - 4",
      path: img4,
      title: t("modernization-card-4-suptitle"),
      subtitle: t("modernization-card-4-title"),
    },
    {
      name: "img - 5",
      path: img5,
      title: t("modernization-card-5-suptitle"),
      subtitle: t("modernization-card-5-title"),
    },
  ];

  return (
    <div className="cards">
      <div className="cards-wrapper">
        {cardImages.map((img) => (
          <Pulse key={img.name}>
            <Card img={img} />
          </Pulse>
        ))}
      </div>
    </div>
  );
};

export default Cards;
