import React from "react";
import { Link } from "react-router-dom";

const UsageNavLink = ({ title, path }) => {
  return (
    <Link className="usage-area-link" to={path} target="_blank">
      {title}
    </Link>
  );
};

export default UsageNavLink;
