import React from "react";

import { Accordion } from "react-bootstrap";
import "./accordion.scss";
import Fade from "react-reveal/Fade";

const AccordionAtom = ({ accordionTexts }) => {
  return (
    <div className="accordion-wrapper">
      <div className="container mt-0 mb-0">
        <Accordion className="accordion-atom" defaultActiveKey="0">
          {accordionTexts.map((text, index) => {
            return (
              <Accordion.Item key={index} eventKey={index}>
                <Accordion.Header id="accordion-header">
                  <h4> {text.accordionHeaderTitle}</h4>
                </Accordion.Header>
                <Accordion.Body>
                  <h4>{text.accordionBodyTitle}</h4>
                  <br />
                  <p>{text.accordionText}</p>
                  <br />
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default AccordionAtom;
