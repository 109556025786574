import React from "react";
import "./Reference.Page.style.scss";
// import { useTranslation } from "react-i18next";

function ReferenceBanner() {
  // const { t } = useTranslation();

  return (
    <div>
      <section className="aboutUsSection-1">
        <img
          className="d-block w-100  eskalator"
          src="https://ake.com.tr/uploads/images/banners%20yeni/banner_yuruyen_merdiven_yeni.jpg"
          alt=""
        />
      </section>
    </div>
  );
}

export default ReferenceBanner;
