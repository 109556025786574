import React from "react";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";

function Showcase() {
  const { t } = useTranslation("products");

  return (
    <div className="ProductsShowcase">
      <div className="text-wrapper">
        <h1 className="title">{t("showcase-title")}</h1>
        <br />
      </div>
    </div>
  );
}

export default Showcase;
