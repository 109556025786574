import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";

function MyVerticallyCenteredModal(props) {
  if (props.type === "videos") {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <iframe
          width="100%"
          height="500"
          src={props.link}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </Modal>
    );
  }

  if (props.type === "photos") {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Carousel fade>
          {props?.photos?.map((photo, index) => (
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={photo}
                style={{ height: "500px" }}
                alt={`${index}-slide`}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal>
    );
  }
}

function App({ link, type, photos }) {
  const [modalShow, setModalShow] = React.useState(false);
  const { t } = useTranslation("branches");
  return (
    <>
      <br />
      {type === "videos" && (
        <Button variant="primary" onClick={() => setModalShow(true)}>
          {t("See video")}
        </Button>
      )}

      {type === "photos" && (
        <Button variant="primary" onClick={() => setModalShow(true)}>
          {t("See photos")}
        </Button>
      )}

      <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        link={link}
        type={type}
        photos={photos}
      />
    </>
  );
}

export default App;
