import React from "react";
import ReferenceBanner from "./components/Reference_Banner";
import Blogs from "./components/Blogs/Blogs";
import Text from "./components/Text/Text";

function References() {
  return (
    <div>
      <ReferenceBanner />
      <Text />
      <Blogs />
    </div>
  );
}

export default References;
