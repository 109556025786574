import React from "react";
import { useTranslation } from "react-i18next";
import Icon1 from "./images/whatmakesusunique.png";
import Icon2 from "./images/whatwelookfor.png";
import Icon3 from "./images/whatweoffer.png";
import ApartmentCreator from "./ApartmentCreator";
import Fade from "react-reveal/Fade";

function Apartment() {
  const { t } = useTranslation("career");
  const obj = [
    {
      img: Icon1,
      title: t("uniqueness"),
      texts: [
        t("imagination"),
        t("believe"),
        t("voices"),
        t("better"),
        t("strive"),
      ],
    },
    {
      img: Icon2,
      title: t("looking-for"),
      texts: [
        t("Imagination"),
        t("Cooperation"),
        t("Involvement"),
        t("Empathy"),
        t("Opportunity-development"),
        t("Pace"),
      ],
    },
    {
      img: Icon3,
      title: t("offer"),

      texts: [
        t("work"),
        t(
          "place"
        ),
        t("career"),
        t("packages"),
      ],
    },
  ];

  return (
    <div className="container">
      <div className="Apartment">
        <Fade bottom>
          <h1>{t("moto")}</h1>
        </Fade>

        <div className="article">
          {obj.map((article) => (
            <ApartmentCreator
              img={article.img}
              title={article.title}
              text={article.texts}
              key={article.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Apartment;
