import React from "react";
import Showcase from "./Showcase";
import TextPage from "./TextPage";
import Cards from "./Cards";
import Gallery from "./Gallery";
import Card2 from "./Card2";

function Modernization() {
  return (
    <div>
      <Showcase />
      <TextPage />
      <Cards />
      <Gallery />
      <Card2 />
    </div>
  );
}

export default Modernization;
