import React from "react";
import { Link } from "react-router-dom";
import logoImg from "./logo2.png";


function LogoLinkComponent() {
  return (
    <Link to="/">
      <img className="LogoImg" src={logoImg} alt="" />
    </Link>
  );
}

export default LogoLinkComponent;
