import React from "react";
import { useTranslation } from "react-i18next";
import { Accordion } from "react-bootstrap";
import "./House.style.scss";
import Fade from "react-reveal/Fade";

const HouseAccordion = () => {
  const { t } = useTranslation("usage-areas");

  return (
    <Accordion className="accordion" defaultActiveKey="0">
      <Fade bottom>
        <Accordion.Item eventKey="0">
          <Accordion.Header id="accordion-header">
            <h4> {t("accordion-title")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <h4>{t("accordion-inner-title")}</h4>
            <br />
            <b>{t("accordion-inner-bold")}</b>
            <br />
            <p>{t("accordion-inner-text")}</p>
            <br />
          </Accordion.Body>
        </Accordion.Item>
      </Fade>
      <Fade bottom>
        <Accordion.Item eventKey="1">
          <Accordion.Header id="accordion-header">
            <h4> {t("accordion-title")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <h4>{t("accordion-inner-title")}</h4>
            <br />
            <b>{t("accordion-inner-bold")}</b>
            <br />
            <p>{t("accordion-inner-text")}</p>
            <br />
          </Accordion.Body>
        </Accordion.Item>
      </Fade>
      <Fade bottom>
        <Accordion.Item eventKey="2">
          <Accordion.Header id="accordion-header">
            <h4> {t("accordion-title")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <h4>{t("accordion-inner-title")}</h4>
            <br />
            <b>{t("accordion-inner-bold")}</b>
            <br />
            <p>{t("accordion-inner-text")}</p>
            <br />
          </Accordion.Body>
        </Accordion.Item>
      </Fade>
      <Fade bottom>
        <Accordion.Item eventKey="3">
          <Accordion.Header id="accordion-header">
            <h4> {t("accordion-title")}</h4>
          </Accordion.Header>
          <Accordion.Body>
            <h4>{t("accordion-inner-title")}</h4>
            <br />
            <b>{t("accordion-inner-bold")}</b>
            <br />
            <p>{t("accordion-inner-text")}</p>
            <br />
          </Accordion.Body>
        </Accordion.Item>
      </Fade>
    </Accordion>
  );
};

export default HouseAccordion;
