import { useTranslation } from "react-i18next";
import styles from "./branch.module.scss";
import Fergana from "./images/fergana.jpg";
import Namangan from "./images/namangan.jpg";
import Xorazm from "./images/xorazm.jpg";
import MyVerticallyCenteredModal from "./components/index";
import { FaLocationArrow } from "react-icons/fa";
//import bukhara images
import buhara1 from "./images/buhara/image-1.jpg";
import buhara2 from "./images/buhara/image-2.jpg";
import buhara3 from "./images/buhara/image-3.jpg";
import buhara4 from "./images/buhara/image-4.jpg";
import buhara5 from "./images/buhara/image-5.jpg";
import buhara6 from "./images/buhara/image-6.jpg";
import buhara7 from "./images/buhara/image-7.jpg";
import buhara8 from "./images/buhara/image-8.jpg";
import buhara9 from "./images/buhara/image-9.jpg";
import buhara10 from "./images/buhara/image-10.jpg";
import buhara11 from "./images/buhara/image-11.jpg";
import buhara12 from "./images/buhara/image-12.jpg";

//import andijan images
import Andijan from "./images/andijan/andijan.jpg";

//import tashkent images
import Tashkent from "./images/tashkent/tashkent.jpg";

export default function Branches() {
  const { t } = useTranslation("branches");

  return (
    <div>
      <h1 className="title">{t("Our branches")}</h1>
      <div className={styles.main}>
        <div className={styles.imageWrapper}>
          <img src={Namangan} alt="namangan branch" />
          <div className={styles.black}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>{t("Namangan branch")}</h3>
              <a href="tel:+998 90 048 40 30">+998 90 048 40 30</a>
              <MyVerticallyCenteredModal
                type="videos"
                link="https://www.youtube.com/embed/1fwFsg67UHs"
              />
              <br />
              <a
                target="_blank"
                href="https://www.google.com/maps?q=40.993757,71.634318"
              >
                <FaLocationArrow color="white" size={30} />
              </a>
            </div>
          </div>
        </div>

        <div className={styles.imageWrapper}>
          <img src={Fergana} alt="fergana branch" />
          <div className={styles.black}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>{t("Fergana branch")}</h3>
              <a href="tel:+998 90 938 40 30">+998 90 938 40 30</a>
              <MyVerticallyCenteredModal
                type="videos"
                link="https://www.youtube.com/embed/ak99icl39Vw"
              />
              <br />
              <a
                target="_blank"
                href="https://www.google.com/maps?q=40.377314,71.753002"
              >
                <FaLocationArrow color="white" size={30} />
              </a>
            </div>
          </div>
        </div>

        <div className={styles.imageWrapper}>
          <img src={Xorazm} alt="xorazm branch" />
          <div className={styles.black}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>{t("Khorezm branch")}</h3>
              <a href="tel:+998 91 772 40 30">+998 91 772 40 30</a>

              <br />
              <a
                target="_blank"
                href="https://www.google.com/maps?q=41.560861,60.656915"
              >
                <FaLocationArrow color="white" size={30} />
              </a>
            </div>
          </div>
        </div>

        <div className={styles.imageWrapper}>
          <img src={Andijan} alt="andijan branch" />
          <div className={styles.black}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>{t("Andijan branch")}</h3>
              <a href="tel:+998 90 069 40 30">+998 90 069 40 30</a>

              <MyVerticallyCenteredModal
                type="videos"
                link="https://www.youtube.com/embed/cBAAs5q8GOY"
              />
              <br />
              <a
                target="_blank"
                href="https://www.google.com/maps?q=40.761711,72.360876"
              >
                <FaLocationArrow color="white" size={30} />
              </a>
            </div>
          </div>
        </div>

        <div className={styles.imageWrapper}>
          <img src={buhara1} alt="Buhara branch" />
          <div className={styles.black}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>{t("Bukhara branch")}</h3>
              <a href="tel:+998 90 399 40 30">+998 90 399 40 30</a>

              <MyVerticallyCenteredModal
                type="videos"
                link="https://www.youtube.com/embed/sXWx7nFYd_E"
              />
              <MyVerticallyCenteredModal
                type="photos"
                photos={[
                  buhara1,
                  buhara2,
                  buhara3,
                  buhara4,
                  buhara5,
                  buhara6,
                  buhara7,
                  buhara8,
                  buhara9,
                  buhara10,
                  buhara11,
                  buhara12,
                ]}
                link="https://www.youtube.com/embed/qQv-duv9nvo"
              />
              <br />
              <a
                target="_blank"
                href="https://www.google.com/maps?q=39.760535,64.451902"
              >
                <FaLocationArrow color="white" size={30} />
              </a>
            </div>
          </div>
        </div>

        <div className={styles.imageWrapper}>
          <img src={Tashkent} alt="Tashkent branch" />
          <div className={styles.black}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <h3>{t("Tashkent branch")}</h3>
              <a href="tel:+998 90 938 40 30">+998 90 938 40 30</a>

              <br />
              <a
                target="_blank"
                href="https://maps.google.com/maps?q=41.348535,69.340196"
              >
                <FaLocationArrow color="white" size={30} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
