import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const Section_4 = ({ src, path, title }) => {
  return (
    <div className="Section_4-container">
      <Link to={path}>
        <div className="Section_4-container-inside">
          <div className="card-img">
            <img src={src} alt="" />
          </div>
          <Fade right>
            <h3 className="Section_4-container-title">{title}</h3>
          </Fade>
        </div>
      </Link>
      <div className="Section_4-container-line"></div>
    </div>
  );
};

export default Section_4;
