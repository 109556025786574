import React from "react";
import { useTranslation } from "react-i18next";

import "./House.style.scss";

const HouseShowcase = () => {
  const { t } = useTranslation("usage-areas");

  return (
    <div className="house-showcase">
      <div className="text-wrapper">
        <h1 className="title">{t("house-showcase-title")}</h1>
        <br />
        <p>{t("house-showcase-text")}</p>
      </div>
    </div>
  );
};

export default HouseShowcase;
