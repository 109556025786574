import React from "react";
import "./sections.style.scss";
// import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Flip from "react-reveal/Flip";

function Section_2() {
  const { t } = useTranslation("about-us");
  return (
    <div className="aboutus-container">
      <section className="aboutUsSection-2">
        <img
          className="d-block w-100 women-img"
          src="https://omuscmslfrpcdn03.azureedge.net/documents/256045/3900859/woman_waiting_elevator_our_company.jpg/655faf4c-ae8e-99bd-1978-21752c6c7c02?t=1606877571047"
          alt=""
        />

        <Flip>
          <h1 className="aboutus-section-2-text">{t("success")}</h1>
        </Flip>
      </section>
    </div>
  );
}

export default Section_2;
