// Import React
import React from "react";

// Reactstrap Components
import { Container, Row, Col } from "reactstrap";

// Import Local Component
import ProductItem from "../ProductItem";

// Import Backogrund Image
// import BackgroundImage from "../productsBackground.jpg";

// Import Product Items Images
import productItem1 from "../productItem1.jpg";
import productItem2 from "../productItem2.jpg";
// import productItem3 from "../productItem3.jpg";
import ProductsShowcase from "./ProductsShowcase";

// Styles
import "./Products.style.scss";

// Import Translation Module
import { useTranslation } from "react-i18next";

// import Flip from "react-reveal/Flip";
import Fade from "react-reveal/Fade";
import Catalog1 from "./files/catalog1.pdf";
import Catalog2 from "./files/catalog2.pdf";

// Products Component
function Products() {
  const { t } = useTranslation("products");

  return (
    <section id="products" className="products">
      <ProductsShowcase />
      <main>
        <article className="main-part">
          <Container>
            <Row className="d-flex flex-column align-items-center">
              <Col className="main-info col-12 col-lg-7 text-center">
                <Fade bottom>
                  <h2>{t("products-title")}</h2>
                </Fade>
                <Fade bottom>
                  <p>{t("products-text-1")}</p>
                </Fade>
                <Fade bottom>
                  <p>{t("products-text-2")}</p>
                </Fade>
                <Fade bottom>
                  <a href="#!">{t("products-button")}</a>
                </Fade>
              </Col>
              <Col className="main-products col-12 text-center">
                <Fade bottom>
                  <h4>{t("cards-title")}</h4>
                </Fade>
                <Row className="d-flex flex-row justify-content-start flex-wrap">
                  <ProductItem
                    image={productItem1}
                    title={t("card-1-title")}
                    link={Catalog1}
                  />

                  <ProductItem
                    image={productItem2}
                    title={t("card-2-title")}
                    link={Catalog2}
                  />
                </Row>
              </Col>
            </Row>
          </Container>
        </article>
      </main>
    </section>
  );
}

export default Products;
