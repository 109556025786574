import React from "react";
import HouseAccordion from "./Accordion";

import { useTranslation } from "react-i18next";

import Fade from "react-reveal/Fade";

const HouseSection = () => {
  const { t } = useTranslation("usage-areas");
  return (
    <div className="container justify-content-center">
      <Fade bottom>
        <h1 className="title">{t("housing")}</h1>
      </Fade>

      <Fade bottom>
        <p className="text">{t("housing-text")}</p>
      </Fade>
      <br />

      <div className="accordion-outside">
        <h1 className="accordion-title mb-5">{t("accordion-wrapper-title")}</h1>
        <HouseAccordion />
      </div>
    </div>
  );
};

export default HouseSection;
