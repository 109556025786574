import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import "./SinglePost.style.scss";
import Roll from "react-reveal/Roll";
import Fade from "react-reveal/Fade";
import axios from "axios";
import Carousel from "react-bootstrap/Carousel";
import { useTranslation } from "react-i18next";

export default function SinglePost() {
  const location = useLocation();
  const path = location.pathname.split("/")[2];
  const [post, setPost] = useState({});
  const {
    i18n: { language: locale },
  } = useTranslation();
  useEffect(() => {
    const getPost = async () => {
      const res = await axios.get("/api/v1/reports/" + path);
      setPost(res?.data);
    };
    getPost();
  }, [path]);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-10">
          <div className="singlePost">
            <Roll top>
              <h1 className="singlePostTitle">{post?.title?.[locale]}</h1>
            </Roll>

            <div className="singlePostWrapper">
              {post.images && (
                <Carousel className="post-carousel" fade>
                  {post?.images.map((img) => {
                    return (
                      <Carousel.Item>
                        <img
                          className="d-block w-100"
                          src={img.src}
                          alt={post?.title?.[locale]}
                        />
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              )}

              <div className="singlePostInfo">
                <Fade className="mt-3 d-inline-block" left>
                  <span>{new Date(post?.date).toLocaleString(locale)}</span>
                </Fade>
              </div>
              <p className="singlePostDesc">
                <Fade left>{post?.description?.[locale]}</Fade>
              </p>
            </div>
          </div>
        </div>
        <div className="col-2"></div>
      </div>
    </div>
  );
}
