import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import Roll from "react-reveal/Roll";

function Investors() {
  const { t } = useTranslation("homepage");

  return (
    <div className="investors">
      <div className="container justify-content-center">
        <Roll top>
          <h1 className="title">{t("investors-title")}</h1>
        </Roll>

        <br />
        <br />

        <Fade right>
          <p>{t("investors-text")}</p>
        </Fade>

        <Fade left>
          <Link to="#" className="carousel-button-dark dark">
            {t("investors-button")}
          </Link>
        </Fade>
      </div>
    </div>
  );
}

export default Investors;
