import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import Roll from "react-reveal/Roll";

function Join() {
  const { t } = useTranslation("homepage");

  return (
    <div className="join">
      <div className="join-wrapper">
        <Roll top>
          <p className="title">{t("join-suptitle")}</p>
        </Roll>

        <Fade right>
          <h1>
            {t("join-title-1")} <br /> {t("join-title-2")}
          </h1>
        </Fade>

        <Fade left>
          <Link to="/career" className="carousel-button">
            {t("join-button")}
          </Link>
        </Fade>
      </div>
    </div>
  );
}

export default Join;
