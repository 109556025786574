import React from "react";
import "./TextPage.scss";
import Fade from "react-reveal/Fade";

const TextPage = ({ textTitle, text, subtitle }) => {
  return (
    <div className="text-page">
      <div className="container">
        <Fade bottom>
          <b style={{ display: "inline-block" }}>{textTitle}</b>
        </Fade>
        <Fade bottom>
          <p className="text-page__text">{text}</p>
        </Fade>
        <Fade bottom>
          <h1>{subtitle}</h1>
        </Fade>
      </div>
    </div>
  );
};

export default TextPage;
