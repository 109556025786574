import React from "react";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import Roll from "react-reveal/Roll";

function Pioneers() {
  const { t } = useTranslation("homepage");

  return (
    <div className="pioneers">
      <div className="container">
        <div className="pioneers-text-wrapper">
          <Roll top>
            <h1 className="title">{t("pioneer-title")}</h1>
          </Roll>

          <br />
          <br />
          <Fade right>
            <p>{t("pioneer-text")}</p>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Pioneers;
