import React from "react";

function SocialComponent({ url, Icon }) {
  return (
    <li>
      <a href={url} target="_blank" rel="noreferrer">
        <Icon style={{ color: "white" }} />
      </a>
    </li>
  );
}

export default SocialComponent;
