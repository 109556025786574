import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router";
import NewsIntro from "./components/News_Intro";
// import NewsPosts from "./components/NewsPosts/NewsPosts";
import NewsSection3 from "./components/NewsSection_3";
import ForMedia from "./components/ForMedia";
// import Single from "./components/Single/Single";
import NewsCarousel from "./components/News-Carousel";
import NewsLastPageImg from "./components/News_LastPage_Img";
import Posts from "../../Pages/Dashboard/components/Posts/Posts";
import BetaNews from "./components/BetaNews";
import axios from "axios";

function News() {
  const [reports, setReports] = useState([]);
  // const { search } = useLocation();

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get("/api/v1/reports");
      setReports(res.data);
    };
    fetchPosts();
  }, []);

  return (
    <div>
      <NewsIntro />
      <BetaNews />
      <Posts reports={reports} />
      {/* <NewsSection3 /> */}
      {/* <NewsCarousel /> */}
      <ForMedia />
      <NewsLastPageImg />
    </div>
  );
}

export default News;
