import React from "react";
import { useTranslation } from "react-i18next";
// import { Link } from "react-router-dom";
import "./HealthStyle.scss";

const HealthShowcase = () => {
  const { t } = useTranslation("usage-areas");

  return (
    <div className="health-showcase">
      <div className="text-wrapper">
        <h1 className="title">{t("health-showcase-title")}</h1>
        <br />
        <p>{t("health-showcase-text")}</p>
      </div>
    </div>
  );
};

export default HealthShowcase;
