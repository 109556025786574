import React, { useContext } from "react";

// Import Reactstrap Components
import { Container, Col, Row } from "reactstrap";

// Local Components
import LinkComponent from "../LinkComponent";
import LogoLinkComponent from "../LogoLinkComponent";

// Styles
import "./Navbar.scss";

// Import React Icons
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

import { MenuOpened } from "../../.../../../../MenuOpenedContext";

import { FaTelegramPlane, FaPhone } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const SelectLocale = () => {
  const {
    i18n: { changeLanguage },
  } = useTranslation();

  const changeLocale = (e) => {
    changeLanguage(e.target.value);
    localStorage.setItem("locale", e.target.value);
  };

  return (
    <select
      className="select-language"
      value={localStorage.getItem("locale")}
      onChange={changeLocale}
    >
      <option value="uz">O'zbekcha</option>
      <option value="ru">Русский</option>
      <option value="en">English</option>
      <option value="tr">Türkçe</option>
    </select>
  );
};

// Navbar Component
function NavbarComponent() {
  const [isOpened, setIsOpened] = useContext(MenuOpened);

  return (
    <nav>
      <Container fluid>
        <Row className="d-flex flex-row align-items-center justify-content-between">
          <Col xs="auto" className="nav-logo">
            <LogoLinkComponent />
          </Col>
          <Col xs="auto" className="nav-menu-icon">
            {!isOpened ? (
              <AiOutlineMenu
                color="white"
                size="32"
                onClick={() => setIsOpened(!isOpened)}
              />
            ) : (
              <AiOutlineClose
                color="white"
                size="32"
                onClick={() => setIsOpened(!isOpened)}
              />
            )}
          </Col>
          <Col
            xs="auto"
            className={`nav-menu-list${isOpened ? " opened" : ""}`}
          >
            <ul className="sub-links">
              <LinkComponent text="news" url="/news" link="header" />
              <LinkComponent text="rd-center" url="/rdcenter" link="header" />
              <LinkComponent text="career" url="/career" link="header" />
              <LinkComponent text="contacts" url="/contact" link="header" />
              <SelectLocale />
            </ul>
            <ul className="main-links">
              <LinkComponent url="/products" text="products" link="header" />
              <LinkComponent text="about" url="/aboutus" link="header" />
              <LinkComponent
                text="modernization"
                url="/modernization"
                link="header"
              />
              <LinkComponent
                url="/servicemaintenance"
                text="servicemaintenance"
                link="header"
              />

              <LinkComponent
                url="/usageareas"
                text="usageareas"
                link="header"
              />
              <LinkComponent
                url="/references"
                text="references"
                link="header"
              />
              <LinkComponent url="/branches" text="branches" link="header" />
            </ul>
          </Col>
          <Col
            xs="auto"
            className={`nav-menu-list${isOpened ? " opened" : ""}`}
          >
            <ul
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <li>
                <a href="https://t.me/+998900424030">
                  <FaTelegramPlane size={30} color="white" />
                </a>
              </li>
              <li>
                <a href="tel:+998900424030">
                  <FaPhone size={30} color="white" />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </nav>
  );
}

export default NavbarComponent;
