import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import img1 from "./images/secondCarusel-slide-1.jpg";
import img2 from "./images/secondCarusel-slide-2.jpg";
import img3 from "./images/secondCarusel-slide-3.jpg";
import img4 from "./images/secondCarusel-slide-4.jpg";

function SecondCarousel() {
  const { t } = useTranslation("homepage");
  return (
    <div className="carousel-wrapper">
      <div className="Carousel">
        <Carousel fade>
          <Carousel.Item>
            <img className="d-block w-100" src={img1} alt="First slide" />
            <div className="carousel-item-text-wrapper">
              <h2 className="carousel-title ">
                {t("second-carousel-1-title")}
              </h2>
              <p>{t("second-carousel-1-desc")}</p>
              <Link to="#" className="carousel-button-dark">
                {t("second-carousel-1-button")}
              </Link>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img className="d-block w-100" src={img3} alt="Third slide" />
            <div className="carousel-item-text-wrapper">
              <h2 className="carousel-title ">
                {t("second-carousel-2-title")}
                <sup>TM</sup>
              </h2>
              <p>{t("second-carousel-2-desc")}</p>
              <Link to="#" className="carousel-button-dark">
                {t("second-carousel-2-button")}
              </Link>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img className="d-block w-100" src={img4} alt="Third slide" />
            <div className="carousel-item-text-wrapper">
              <h2 className="carousel-title ">
                {t("second-carousel-3-title")}
              </h2>
              <p>{t("second-carousel-3-desc")}</p>
              <Link to="#" className="carousel-button-dark">
                {t("second-carousel-3-button")}
              </Link>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default SecondCarousel;
