import React from "react";

import HouseSection from "./houseSection";
import HouseShowcase from "./HouseShowcase";

const House = () => {
  return (
    <div>
      <HouseShowcase />
      <HouseSection />
    </div>
  );
};

export default House;
