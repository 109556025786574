import React from "react";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

const JumpingCard = ({ src, path, title }) => {
  return (
    <div className="cardJump">
      <Link to={path}>
        <div className="cardJump-inside">
          <div className="card-img-wrapper">
            <img src={src} alt="" />
          </div>
          <Fade bottom>
            <h3 className="cardJump-title">{title}</h3>
          </Fade>
        </div>
      </Link>
      <div className="line"></div>
    </div>
  );
};

export default JumpingCard;
