import React from "react";
import Fade from "react-reveal/Fade";

function ApartmentCreator({ img, title, text }) {
  return (
    <div className="article-item">
      <Fade bottom>
        <div className="icon-wrapper">
          <img src={img} alt="" />
        </div>
      </Fade>

      <Fade bottom>
        <h5 className="mt-4 text-uppercase fw-bold">{title}</h5>
      </Fade>

      <Fade bottom>
        {text.map((txt, index) => {
          return (
            <div>
              {index !== 0 ? <div className="line-icon"></div> : <br />}
              <p>{txt}</p>
            </div>
          );
        })}
      </Fade>
    </div>
  );
}

export default ApartmentCreator;
