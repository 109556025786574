import React from "react";
import { useTranslation } from "react-i18next";
import Showcase from "../usageAreaComponentsAtom/showcase/showcase";
import TextPage from "../usageAreaComponentsAtom/Text/TextPage";

const CarPark = () => {
  const { t } = useTranslation("usage-areas");
  const showcaseTitle = t("car-parking-showcase-title");
  const showcaseText = t("car-parking-showcase-text");
  const textPageTitle = t("car-parking-title");
  const textPageText = t("car-parking-text");
  return (
    <div>
      <Showcase
        title={showcaseTitle}
        text={showcaseText}
        className={"car-park"}
      />
      <TextPage textTitle={textPageTitle} text={textPageText} />
    </div>
  );
};

export default CarPark;
