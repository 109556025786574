import React from "react";
import "./Single.style.scss";
import SinglePost from "../../../Dashboard/components/SinglePost/SinglePost";
function Single() {
  return (
    <div className="container">
      <div className="Single">
        <SinglePost />
      </div>
    </div>
  );
}

export default Single;
