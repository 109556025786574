import React from "react";
import { useTranslation } from "react-i18next";
import Roll from "react-reveal/Roll";
import Fade from "react-reveal/Fade";

const LookFor = () => {
  const { t } = useTranslation("career");
  return (
    <div className="CareersTextPage">
      <div className="wrapper">
        <Roll top>
          <h1 className="title">{t("challenges-title")}</h1>
        </Roll>
        <br />
        <br />
        <Fade right>
          <p>{t("challenges-description")}</p>
        </Fade>
        <br />
        <br />
        <Fade left>
          <button className="button-dark">{t("challenges-button")}</button>
        </Fade>
      </div>
    </div>
  );
};

export default LookFor;
