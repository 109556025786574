import React, { useEffect, useState } from "react";
import HomePage from "./Pages/HomePage/HomePage";
import AboutUs from "./Pages/AboutUs/aboutUs";
import Products from "./Pages/ProductsUsageAreas/products/Products";
import UsageAreas from "./Pages/ProductsUsageAreas/usageAreas/UsageAreas";
import References from "./Pages/References/References";
import Modernization from "./Pages/TechnicalService/modernization/Modernization";
import ServiceMaintenance from "./Pages/TechnicalService/serviceMaintenance/ServiceMaintenance";
import News from "./Pages/News/News";
import RDCenter from "./Pages/RDCenter/RDCenter";
import Careers from "./Pages/Careers/Careers";
import ContactPage from "./Pages/ContactPage/ContactPage";
import Layout from "./Components/Layout/Layout";

/* I18N */
import "./i18n";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.scss";

import House from "./Pages/ProductsUsageAreas/usageAreas/House/House";
import Health from "./Pages/ProductsUsageAreas/usageAreas/Health/Health";
import Stadium from "./Pages/ProductsUsageAreas/usageAreas/Stadium/Stadium";
import ShoppingMall from "./Pages/ProductsUsageAreas/usageAreas/Mywork/index";
import Hotels from "./Pages/ProductsUsageAreas/usageAreas/Hotels/Hotels";
import Restaurants from "./Pages/ProductsUsageAreas/usageAreas/Restaurants/Restaurants";
import CarPark from "./Pages/ProductsUsageAreas/usageAreas/CarPark/CarPark";
import Panoramic from "./Pages/ProductsUsageAreas/usageAreas/Panoramic/Panoramic";
import Solutions from "./Pages/ProductsUsageAreas/usageAreas/Solutions/Solutions";
import ProductIndividualPage from "./Pages/ProductsUsageAreas/products/productPage/SingleProductItem";
import Dashboard from "./Pages/Dashboard/DashboardPage";
import Login from "./Pages/Dashboard/components/Login/Login";
import Register from "./Pages/Dashboard/components/Register/Register";
import Write from "./Pages/Dashboard/components/Write/Write";
import Settings from "./Pages/Dashboard/components/Settings/Settings";
import SinglePage from "./Pages/News/components/Single/Single";
import Branches from "./Pages/Branches";

import ScrollToTop from "./ScrollToTop";

import { MenuChecked } from "./MenuCheckedContext";
import { MenuOpened } from "./MenuOpenedContext";

function App() {
  const [isOpened, setIsOpened] = useState(false);
  const [checked, setChecked] = useState(false);

  const user = true;

  useEffect(() => {
    if (!localStorage.getItem("locale")) {
      localStorage.setItem("locale", "uz");
    }
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <MenuChecked.Provider value={[checked, setChecked]}>
          <MenuOpened.Provider value={[isOpened, setIsOpened]}>
            <div className="MainPage">
              <Layout>
                <Switch>
                  <Route exact path="/" component={HomePage} />
                  <Route path="/aboutus" component={AboutUs} />
                  <Route path="/contact" component={ContactPage} />

                  <Route exact path="/products" component={Products} />
                  <Route exact path="/usageareas" component={UsageAreas} />

                  <Route path="/references" component={References} />
                  <Route path="/modernization" component={Modernization} />
                  <Route
                    path="/servicemaintenance"
                    component={ServiceMaintenance}
                  />
                  <Route path="/news" component={News} />
                  <Route path="/rdcenter" component={RDCenter} />
                  <Route path="/career" component={Careers} />
                  <Route exact path="/usageareas/house" component={House} />
                  <Route exact path="/usageareas/stadium" component={Stadium} />
                  <Route exact path="/usageareas/health" component={Health} />
                  <Route exact path="/branches" component={Branches} />
                  <Route
                    exact
                    path="/usageareas/restaurants"
                    component={Restaurants}
                  />
                  <Route
                    exact
                    path="/usageareas/shopping-mall"
                    component={ShoppingMall}
                  />
                  <Route exact path="/usageareas/hotels" component={Hotels} />
                  <Route
                    exact
                    path="/usageareas/car-park"
                    component={CarPark}
                  />
                  <Route
                    exact
                    path="/usageareas/panoramic"
                    component={Panoramic}
                  />
                  <Route
                    exact
                    path="/usageareas/solutions"
                    component={Solutions}
                  />
                  <Route
                    exact
                    path="/products/product-individual-page"
                    component={ProductIndividualPage}
                  />
                  <Route path="/login">
                    <Login />
                  </Route>
                  <Route path="/register">
                    {user ? <HomePage /> : <Register />}
                  </Route>
                  <Route path="/settings">
                    {user ? <Settings /> : <Register />}
                  </Route>
                  <Route path="/write">
                    {user ? <Write /> : <Register />} /
                  </Route>
                  <Route path="/post/:postId" component={SinglePage} />
                </Switch>
              </Layout>
            </div>
          </MenuOpened.Provider>
        </MenuChecked.Provider>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
