import React from "react";
import "./HomePage.style.scss";
import CarouselComponent from "./Carousel.js";
import WhoWeAre from "./WhoWeAre.js";
import Pioneers from "./Pioneers.js";
import SecondCarousel from "./SecondCarousel.js";
import Investors from "./Investors.js";
import Join from "./Join.js";

function HomePage() {
  return (
    <>
      <CarouselComponent />
      <WhoWeAre />
      <Pioneers />
      <SecondCarousel />
      <Investors />
      <Join />
    </>
  );
}

export default HomePage;
