import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Galleries = () => {
  const { t } = useTranslation("career");

  return (
    <div className="careers-galleries">
      <div className="gallery-1">
        <div className="gallery-item-text">
          <div className="text">
            <h3>{t("beta-work")}</h3>
            <br />
            <p>{t("modernization")}</p>

            <Link to="/contact" className="gallery-button">
              <button className="button-dark">{t("contact")}</button>
            </Link>
          </div>
        </div>
        <div className="gallery-item-img-1"></div>
      </div>

      <div className="gallery-2">
        <div className="gallery-item-img-2"></div>
        <div className="gallery-item-text">
          <div className="text">
            <h4>{t("academy")}</h4>
            <br />
            <b>{t("service")}</b>
            <br />
            <p>{t("commit")}</p>

            <br />
            <Link to="/contact">
              <button className="button-dark">{t("contact")}</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="gallery-1">
        <div className="gallery-item-text">
          <div className="text">
            <h3>{t("training-title")}</h3>
            <br />
            <p>{t("training-text")}</p>

            <Link to="/contact" className="gallery-button">
              <button className="button-dark">{t("contact")}</button>
            </Link>
          </div>
        </div>
        <div className="gallery-item-img-3"></div>
      </div>

      <div className="gallery-2">
        <div className="gallery-item-img-4"></div>
        <div className="gallery-item-text">
          <div className="text">
            <h4>{t("double-4-title")}</h4>
            <br />
            <b>{t("double-4-bold")}</b>
            <br />
            <p>{t("double-4-description")}</p>
            <br />
            <Link to="/contact">
              <button className="button-dark">{t("contact")}</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Galleries;
