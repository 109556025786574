import React from "react";
import "./Blog.scss";
import { useTranslation } from "react-i18next";

function Blog({ post, getReference }) {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation();

  return (
    <div className="card-wrapper">
      <div className="card-img-wrapper">
        {post.images && (
          <img className="singlePostImg" src={post?.images[0]?.src} alt="" />
        )}
      </div>
      <span className="post-date">
        {new Date(post?.date).toLocaleString(locale)}
      </span>
      <h4 className="blog-title">{post?.title?.[locale]}</h4>

      <p className="post-description">{post?.description?.[locale]}</p>

      <button onClick={() => getReference(post._id)} className="button-dark">
        {t("LEARN MORE")}
      </button>
    </div>
  );
}

export default Blog;
