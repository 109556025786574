// Import React Component
import React from "react";

// Import Reactstrap Components
import { Container } from "reactstrap";

// Import Styles
import "./Stadium.style.scss";

// Import Translation Module
import { useTranslation } from "react-i18next";

// Import Stadium Header Background Image
import headerBackground from "./stadiumHeaderBackground.jpg";

// Import React Icons
import { AiOutlineDoubleRight } from "react-icons/ai";
import Roll from "react-reveal/Roll";
import Fade from "react-reveal/Fade";
import StadiumShowcase from "./StadiumShowcase";

function Stadium() {
  const { t } = useTranslation("usage-areas");

  return (
    <>
      <section id="stadium" className="stadium">
        <StadiumShowcase />
        <main>
          <article className="stadium__description">
            <Container>
              <div className="stadium__description__content">
                <Fade bottom>
                  <b style={{ display: "inline-block" }}>
                    {t("stadium-title")}
                  </b>
                </Fade>
                <Fade bottom>
                  <p>{t("stadium-text")}</p>
                </Fade>
              </div>
            </Container>
          </article>
        </main>
      </section>
    </>
  );
}

export default Stadium;
