// Import React Component
import React from "react";

// Import Translation Module
import { useTranslation } from "react-i18next";

// Import Reactstrap Components
import { Col } from "reactstrap";

function CardItem({ title, cardImage }) {
  const { t } = useTranslation();

  return (
    <Col className="single-product-card">
      <div className="single-product-card-image-wrapper">
        <img src={cardImage} alt={t(`${title}`)} />
      </div>
      <div className="single-product-card-content-wrapper">
        <h3>{t(`${title}`)}</h3>
      </div>
    </Col>
  );
}

export default CardItem;
