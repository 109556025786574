import React, { useState } from "react";
import { Carousel } from "react-bootstrap";
import Cimage from "../img/Shopping Light (1).jpeg";
import Cimage2 from "../img/Toronto-Finch-West-station-low-concourse.jpg";
import Cimage3 from "../img/ZMP robot front.jpg";

function News_Carousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className="carousel-wrapper">
      <section className="Carousel-page">
        <Carousel activeIndex={index} onSelect={handleSelect}>
          <Carousel.Item className="carousel-item">
            <img className="d-block w-100" src={Cimage} alt="First slide" />
            <Carousel.Caption>
              <h3>First slide label</h3>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={Cimage2} alt="Second slide" />

            <Carousel.Caption>
              <h3>Second slide label</h3>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={Cimage3} alt="Third slide" />

            <Carousel.Caption>
              <h3>Third slide label</h3>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </section>
    </div>
  );
}

export default News_Carousel;
