import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Card = ({ img }) => {
  const { t } = useTranslation("usage-areas");
  return (
    <div className="card-wrapper">
      <p>{img.title}</p>
      <div className="card-img-wrapper">
        <img src={img.path} alt="" />
      </div>
      <h4>{img.subtitle}</h4>
      <Link to="#" className="button-link">
        <button className="button-dark">{t("area-card-button")}</button>
      </Link>
    </div>
  );
};

export default Card;
