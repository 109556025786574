import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Gallery = () => {
  const { t } = useTranslation("modernization");

  return (
    <div className="modernization-galleries">
      <div className="gallery-1">
        <div className="gallery-item-text">
          <div className="text">
            <h3>{t("modernization-gallery-1-title")}</h3>
            <br />
            <p>{t("modernization-gallery-1-text")}</p>

            <Link to="#" className="gallery-button">
              <button className="button-dark">
                {t("modernization-gallery-1-button")}
              </button>
            </Link>
          </div>
        </div>
        <div className="gallery-item-img"></div>
      </div>

      <div className="gallery-2">
        <div className="gallery-item-img"></div>
        <div className="gallery-item-text">
          <div className="text">
            <h4>{t("modernization-gallery-2-title")}</h4>
            <br />
            <p className="modernization-text-with-bold">
              {t("modernization-gallery-2-bold")}
            </p>
            <br />
            <br />
            <Link to="#">
              <button className="button-dark">
                {t("modernization-gallery-2-button")}
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
