import React, { useState, useEffect } from "react";
import Blog from "../Blog/Blog";
import "./Blogs.scss";
import axios from "axios";
import { Carousel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Fade, Roll } from "react-reveal";
import { AiOutlineClose } from "react-icons/ai";

function Blogs() {
  const [posts, setPosts] = useState([]);
  const [modal, setModal] = useState(false);
  const [post, setPost] = useState({});

  useEffect(() => {
    const fetchPosts = async () => {
      const res = await axios.get("/api/v1/posts");
      setPosts(res.data);
    };
    fetchPosts();
  }, []);

  const {
    t,
    i18n: { language: locale },
  } = useTranslation();

  async function getReference(id) {
    const res = await axios.get(`/api/v1/posts/${id}`);
    setModal(true);
    setPost(res.data);
    console.log(res.data);
  }

  return (
    <>
      <div className="container">
        <div className="reference-cards">
          {posts.map((post) => {
            return (
              <Blog key={post?.id} getReference={getReference} post={post} />
            );
          })}
        </div>
      </div>
      {modal && (
        <div className="modal-window">
          <span onClick={() => setModal(false)} className="close-button">
            <AiOutlineClose color="white" />
          </span>
          <div onClick={() => setModal(false)} className="background"></div>
          <div className="modal-inside">
            <div className="images">
              <div className="reference-carousel-img">
                {post.images && (
                  <div className="reference-carousel-wrapper">
                    <Carousel className="reference-carousel">
                      {post?.images.map((img) => {
                        return (
                          <Carousel.Item>
                            <img
                              className="d-block w-100"
                              src={img.src}
                              alt={post?.title?.[locale]}
                            />
                          </Carousel.Item>
                        );
                      })}
                    </Carousel>
                  </div>
                )}
              </div>
            </div>
            <div className="texts">
              <div className="texts-inside">
                <div className="singlePost">
                  <h1 className="singlePostTitle">{post?.title?.[locale]}</h1>

                  <div className="singlePostWrapper">
                    <div className="singlePostInfo">
                      <Fade className=" d-inline-block" left>
                        <span>
                          {new Date(post?.date).toLocaleString(locale)}
                        </span>
                      </Fade>
                    </div>
                    <p className="singlePostDesc">
                      <Fade left>{post?.description?.[locale]}</Fade>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Blogs;
