import React, { useContext, useRef } from "react";
import "./Login.scss";
import { Link } from "react-router-dom";
import { Context } from "../../../../Context/Context";
import axios from "axios";
import { useHistory } from "react-router-dom";

const BACKEND_URL = "http://localhost:5000/api";

function Login() {
  const history = useHistory();
  const userRef = useRef();
  const passwordRef = useRef();
  const { user } = useContext(Context);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // dispatch({ type: "LOGIN_START" });
    try {
      const res = await axios.post(`${BACKEND_URL}/auth/login`, {
        username: userRef.current.value,
        password: passwordRef.current.value,
      });

      console.log(res?.data);
      history.push("/write");

      // dispatch({ type: "LOGIN_SUCCESS", payload: res?.data });
    } catch (err) {
      console.log(err);
      // dispatch({ type: "LOGIN_FAILURE" });
    }
  };
  console.log(user);
  return (
    <div className="login">
      <span className="loginTitle">Login</span>
      <form className="loginForm" onSubmit={handleSubmit}>
        <label>Username</label>
        <input
          className="loginInput"
          type="text"
          placeholder="Enter your email..."
          ref={userRef}
        />
        <label>Password</label>
        <input
          className="loginInput"
          type="password"
          placeholder="Enter your password..."
          ref={passwordRef}
        />
        <button className="loginButton" type="submit">
          Login
        </button>
      </form>

      <button className="loginRegisterButton">
        <Link to="/register" className="RegisterLink">
          Register
        </Link>
      </button>
    </div>
  );
}

export default Login;
