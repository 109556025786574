import React from "react";
import "./news.style.scss";
import { useTranslation } from "react-i18next";
// import Fade from "react-reveal/Fade";
import Fade from "react-reveal/Fade";

function NewsIntro() {
  const { t } = useTranslation("news");

  return (
    <div className="news-container">
      <section className="news-section-1">
        <div className="news-section-1-text">
          <Fade bottom>
            <h1>{t("showcase-title")}</h1>
          </Fade>
          <Fade bottom>
            <button className="new-section-1-btn">
              {t("showcase-button")}
            </button>
          </Fade>
        </div>
        <div></div>
      </section>
    </div>
  );
}

export default NewsIntro;
