import React from "react";
import Post from "../Post/Post";
import "./Posts.style.scss";
function Posts({ reports }) {
  return (
    <div className="container">
      <div className="posts">
        {reports.map((p, index) => (
          <Post reports={p} key={index} />
        ))}
      </div>
    </div>
  );
}
export default Posts;
