import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import firstImg from "./images/slide-img-1.jpg";
import secondImg from "./images/slide-img-2.jpg";
import fourthImg from "./images/slide-img-4.jpg";

function CarouselComponent() {
  const { t } = useTranslation("homepage");
  return (
    <div className="carousel-wrapper">
      <div className="Carousel">
        <Carousel fade>
          <Carousel.Item>
            <img className="d-block w-100" src={firstImg} alt="First slide" />
            <div className="carousel-item-text-wrapper">
              <h2 className="carousel-title ">{t("gallery-1-title")}</h2>
              <p>{t("gallery-1-desc")}</p>
              <Link to="#" className="carousel-button">
                {t("gallery-1-button")}
              </Link>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img className="d-block w-100" src={secondImg} alt="Second slide" />
            <div className="carousel-item-text-wrapper">
              <h2>
                {t("gallery-2-first-line")} <br className="break" />
                {t("gallery-2-second-line")} <br className="break" />
                {t("gallery-2-third-line")}
              </h2>
              <p>{t("gallery-2-desc")}</p>
              <br />
              <br />
              <p>{t("gallery-2-text")}</p>
              <Link to="#" className="carousel-button  mr-2">
                {t("register")}
              </Link>
              <Link to="#" className="carousel-button ">
                {t("learn-how")}
              </Link>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <img className="d-block w-100" src={fourthImg} alt="Third slide" />
            <div className="carousel-item-text-wrapper">
              <h2 className="carousel-title ">{t("gallery-3-title")}</h2>
              <p>{t("gallery-3-desc")}</p>
              <Link to="#" className="carousel-button">
                {t("gallery-3-button")}
              </Link>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
}

export default CarouselComponent;
