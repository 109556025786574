import React from "react";
import { useTranslation } from "react-i18next";
import "./Text.scss";
function Text() {
  const { t } = useTranslation("references");
  return (
    <section className="Text  justify-content-center">
      <div className="Text-text">
        <h1 className="Text-h1">{t("references-title")}</h1>
        <div className="Text-line"></div>
      </div>
    </section>
  );
}

export default Text;
