import React from "react";
import "./RDCenter.style.scss";
import Showcase from "./Showcase";
import Text from "./Text";

function RDCenter() {
  return (
    <div>
      <Showcase />
      <Text />
    </div>
  );
}

export default RDCenter;
