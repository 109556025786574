import React from "react";
import { useTranslation } from "react-i18next";

function Showcase() {
  const { t } = useTranslation("usage-areas");

  return (
    <div className="UsageAreasShowcase">
      <div className="text-wrapper">
        <h1 className="title">{t("showcase-title")}</h1>
        <br />
      </div>
    </div>
  );
}

export default Showcase;
