import React from "react";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

function Showcase() {
  const { t } = useTranslation("usage-areas");
  return (
    <div className="img-wrapper">
      <Fade bottom>
        <h1>{t("rdcenter-showcase-title")}</h1>
      </Fade>
    </div>
  );
}

export default Showcase;
