// Import React Component
import React from "react";

// Import Styles
import "./SingleProductItem.style.scss";

// Import Card Component
import CardItem from "./CardItem";

// Import Reactstrap Components
import { Container, Row } from "reactstrap";

// Import Translation Module
import { useTranslation } from "react-i18next";

// Header Background Image
import headerBackgroundImage from "./singleProductItemHeaderBackground.jpg";

// Card Items Images
import cardItem1 from "./signleProductItemCardItem1.jpg";
import cardItem2 from "./signleProductItemCardItem2.jpg";
import cardItem3 from "./signleProductItemCardItem3.jpg";
import cardItem4 from "./signleProductItemCardItem4.jpg";
import cardItem5 from "./signleProductItemCardItem5.jpg";
import cardItem6 from "./signleProductItemCardItem6.jpg";

function SingleProductItem() {
  const { t } = useTranslation();

  return (
    <section className="single-product-items">
      <header>
        <div className="header-image-wrapper">
          <img src={headerBackgroundImage} alt={t("Background Image")} />
        </div>
        <div className="header-content-wrapper">
          <h1>{t("Elevator products")}</h1>
        </div>
      </header>
      <main>
        <article id="gen2">
          <Container>
            <h2>{t("Gen2®")}</h2>
            <Row
              className="d-flex align-items-start justify-content-start flex-wrap"
              style={{ margin: "0", padding: "0", width: "100%" }}
            >
              <CardItem title="passenger car" cardImage={cardItem1} />
              <CardItem title="full automatic doors" cardImage={cardItem2} />
              <CardItem
                title="panoramic passenger cars"
                cardImage={cardItem3}
              />
              <CardItem title="semi automatic doors" cardImage={cardItem4} />
              <CardItem
                title="goods bed automobile cars"
                cardImage={cardItem5}
              />
              <CardItem title="car frame" cardImage={cardItem6} />
            </Row>
          </Container>
        </article>
        <article id="skyrise">
          <Container>
            <h2>{t("Skyrise®")}</h2>
            <Row
              className="d-flex align-items-start justify-content-start flex-wrap"
              style={{ margin: "0", padding: "0", width: "100%" }}
            >
              <CardItem title="passenger car" cardImage={cardItem1} />
              <CardItem title="full automatic doors" cardImage={cardItem2} />
              <CardItem
                title="panoramic passenger cars"
                cardImage={cardItem3}
              />
              <CardItem title="semi automatic doors" cardImage={cardItem4} />
              <CardItem
                title="goods bed automobile cars"
                cardImage={cardItem5}
              />
              <CardItem title="car frame" cardImage={cardItem6} />
            </Row>
          </Container>
        </article>
        <article id="skybuild">
          <Container>
            <h2>{t("Skybuild™")}</h2>
            <Row
              className="d-flex align-items-start justify-content-start flex-wrap"
              style={{ margin: "0", padding: "0", width: "100%" }}
            >
              <CardItem title="passenger car" cardImage={cardItem1} />
              <CardItem title="full automatic doors" cardImage={cardItem2} />
              <CardItem
                title="panoramic passenger cars"
                cardImage={cardItem3}
              />
              <CardItem title="semi automatic doors" cardImage={cardItem4} />
              <CardItem
                title="goods bed automobile cars"
                cardImage={cardItem5}
              />
              <CardItem title="car frame" cardImage={cardItem6} />
            </Row>
          </Container>
        </article>
      </main>
    </section>
  );
}

export default SingleProductItem;
