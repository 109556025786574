import React from "react";
import { useTranslation } from "react-i18next";
import "./Stadium.style.scss";

const StadiumShowcase = () => {
  const { t } = useTranslation("usage-areas");
  return (
    <div className="stadium-showcase">
      <div className="text-wrapper">
        <h1 className="title">{t("stadium-showcase-title")}</h1>
        <br />
        <p>{t("stadium-showcase-text")}</p>
      </div>
    </div>
  );
};

export default StadiumShowcase;
