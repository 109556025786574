import React from "react";
import "./aboutUs.style.scss";

import Section1 from "./components/Section-1";
import Section2 from "./components/Section-2";
import Section3 from "./components/Section-3";

function AboutUs() {
  return (
    <div className="aboutus-container">
      <Section1 />
      <Section2 />
      <Section3 />
    </div>
  );
}

export default AboutUs;
