import React from "react";
import "./DashboardPage.scss";
import { Button } from "react-bootstrap";

function DashboardPage() {
  return (
    <div className="cantainer">
      <>
        <Button variant="primary">Register</Button>{" "}
        <Button variant="secondary">Login</Button>{" "}
        <Button variant="success">Add News</Button>{" "}
        <Button variant="warning">Settings</Button>{" "}
        <Button variant="danger">Refernce</Button>{" "}
      </>
    </div>
  );
}

export default DashboardPage;
