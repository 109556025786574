import React from "react";
import { useTranslation } from "react-i18next";
import AccordionAtom from "../usageAreaComponentsAtom/accordion/AccordionAtom";
import Showcase from "../usageAreaComponentsAtom/showcase/showcase";
import TextPage from "../usageAreaComponentsAtom/Text/TextPage";

const Panoramic = () => {
  const { t } = useTranslation("usage-areas");
  const showcaseTitle = t("panoramic-showcase-title");
  const showcaseText = t("panoramic-showcase-text");
  const textPageTitle = t("panoramic-title");
  const textPageText = t("panoramic-text");
  const textPageSubtitle = t("panoramic-accordion-wrapper-title");

  const accordionTexts = [
    {
      accordionHeaderTitle: t("panoramic-accordion-title"),
      accordionBodyTitle: t("panoramic-accordion-inner-title"),
      accordionText: t("panoramic-accordion-inner-text"),
    },
  ];

  return (
    <div>
      <Showcase
        title={showcaseTitle}
        text={showcaseText}
        className={"panoramic"}
      />
      <TextPage
        textTitle={textPageTitle}
        text={textPageText}
        subtitle={textPageSubtitle}
      />
      <AccordionAtom accordionTexts={accordionTexts} />
    </div>
  );
};

export default Panoramic;
