import React from "react";
import "./sections.style.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Roll from "react-reveal/Roll";
import Fade from "react-reveal/Fade";
import showcaseBg from "../img/showcaseBg.jpeg";

function Section_1() {
  const { t } = useTranslation("about-us");
  return (
    <div className="aboutus-container">
      <section className="aboutUsSection-1 d-flex flex-column justify-content-center align-items-center">
        <img
          className="d-block w-100 h-100 eskalator position-absolute"
          style={{ zIndex: -1 }}
          src={showcaseBg}
          alt="bg"
        />
        <h1 className="aboutus-section-1-text">{t("showcase")}</h1>
      </section>
      <section className="aboutus-section-2">
        <Roll top>
          <h1 className="aboutus-section-2-h1">{t("moto-title")}</h1>
        </Roll>
        <Fade right>
          <p className="aboutus-section-2-p">{t("moto-text")}</p>
        </Fade>
        <Fade left></Fade>
      </section>
    </div>
  );
}

export default Section_1;
