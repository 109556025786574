import React from "react";
import { useTranslation } from "react-i18next";
import AccordionAtom from "../usageAreaComponentsAtom/accordion/AccordionAtom";
import Showcase from "../usageAreaComponentsAtom/showcase/showcase";
import TextPage from "../usageAreaComponentsAtom/Text/TextPage";

const Solutions = () => {
  const { t } = useTranslation("usage-areas");
  const showcaseTitle = t("solutions-showcase-title");
  const showcaseText = t("solutions-showcase-text");
  const textPageTitle = t("solutions-title");
  const textPageText = t("solutions-text");
  const textPageSubtitle = t("solutions-accordion-wrapper-title");

  const accordionTexts = [
    {
      accordionHeaderTitle: t("solutions-accordion-1-title"),
      accordionBodyTitle: t("solutions-accordion-1-inner-title"),
      accordionText: t(`solutions-accordion-1-inner-text`),
    },
    {
      accordionHeaderTitle: t("solutions-accordion-2-title"),
      accordionBodyTitle: t("solutions-accordion-2-inner-title"),
      accordionText: t("solutions-accordion-2-inner-text"),
    },
  ];

  return (
    <div>
      <Showcase
        title={showcaseTitle}
        text={showcaseText}
        className={"solutions"}
      />
      <TextPage
        textTitle={textPageTitle}
        text={textPageText}
        subtitle={textPageSubtitle}
      />
      <AccordionAtom accordionTexts={accordionTexts} />
    </div>
  );
};

export default Solutions;
