// Import React
import React from "react";

// Reactstrap Components
// import { Container, Row, Col } from "reactstrap";

// Import Local Component
// import ProductItem from "../ProductItem";

// Import Backogrund Image
// import BackgroundImage from "../usageAreasBackground.jpg";

// Import Product Items Images
import productItem1 from "../productItem3.jpg";
import productItem2 from "../productItem5.jpg";

// Styles
import "./UsageAreas.style.scss";
import UsageAreaNav from "./usageAreaNav";
import "./UsageAreas.style.scss";
// import Flip from "react-reveal/Flip";
// import Roll from "react-reveal/Roll";
// import Fade from "react-reveal/Fade";

// Import Translation Module
import { useTranslation } from "react-i18next";
// import Pulse from "react-reveal/Pulse";
import Card from "../../TechnicalService/modernization/Card";
import Showcase from "./UsageAreaShowcase";
import { Link } from "react-router-dom";
// Usage Areas Component
function UsageAreas() {
  const { t } = useTranslation("usage-areas");
  const cardImages = [
    {
      name: "img - 1",
      path: productItem1,
      title: t(""),
      subtitle: t("area-card-2-title"),
    },
    {
      name: "img - 2",
      path: productItem2,
      title: t(""),
      subtitle: t("area-card-3-title"),
    },
  ];

  return (
    <section id="usageAreas" className="usageAreas">
      <Showcase />

      <div className="usage-wrapper">
        <div className="left">
          <div className="left-inner text-center">
            <div className="left-text">
              <h1 className="title">{t("area-card-1-title")}</h1>
              <br />
              <br />
              <p>{t("area-card-1-text")}</p>
              <Link to="/contact">
                <button className="carousel-button">
                  {t("area-card-1-button")}
                </button>
              </Link>
            </div>
            <br />
            <br />
            <div className="left-cards">
              {cardImages.map((card) => (
                <Card key={card.name} img={card} />
              ))}
            </div>
          </div>
        </div>

        <div className="right">
          <UsageAreaNav />
        </div>
      </div>
    </section>
  );
}
export default UsageAreas;
