// Import React
import React from "react";

// Import Reactstrap Components
// import { Col } from "reactstrap";

// Import Translation Module
import { useTranslation } from "react-i18next";

// Import React Router Components
import { Link } from "react-router-dom";
import Pulse from "react-reveal/Pulse";

function ProductItem({ image, title, link }) {
  const { t } = useTranslation("products");

  return (
    <div className="productItem">
      <Pulse>
        <div className="productItem__imageBox">
          <img src={image} alt={t(`${title}`)} />
        </div>
        <div className="productItem__info">
          <h6>{t(`${title}`)}</h6>
          <a target="_blank" href={link}>
            {t("learn more")}
          </a>
        </div>
      </Pulse>
    </div>
  );
}

export default ProductItem;
