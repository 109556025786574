// Import React
import React from "react";

// Import Reactstrap Components
import { Col } from "reactstrap";

// Import Translation Module
import { useTranslation } from "react-i18next";

import Fade from "react-reveal/Fade";

function AdditionalPart({ image, title }) {
  const { t } = useTranslation();

  return (
    <Col className="col-md-5 col-12 mb-5 mb-md-0">
      <div className="resourceImage">
        <img src={image} alt={t(`${title}`)} />
      </div>
      <Fade bottom>
        <a href="#!">{t(`${title}`)}</a>
      </Fade>
    </Col>
  );
}

export default AdditionalPart;
