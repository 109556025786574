import React from "react";
import { Container, Row, Col } from "reactstrap";
import AdditionalPart from "./AdditionalPart";
import resourseImage1 from "./otherLink1.jpg";
import resourseImage2 from "./otherLink2.jpg";
import "./serviceMaintenance.scss";
import productItem4 from "./productItem4.jpg";
import servicesLogos from "./serviceLogos.png";

// Import equipment items images
import equipmentItem1 from "./equipmentItem1.png";
import equipmentItem2 from "./equipmentItem2.png";
import equipmentItem3 from "./equipmentItem3.png";
import equipmentItem4 from "./equipmentItem4.png";

// Import About Us Image
import aboutUsImageBackground from "./sectionBackground.jpg";

// Import Equipment Background Image
import equipmentImageBackground from "./equipmentImageBackground.jpg";

// Import Experience Items Images
import experienceItem1 from "./individualIcon1.png";
import experienceItem2 from "./individualIcon2.png";

// Import Individual Maintenance Images
import individualImageItem1 from "./individualImage1.jpg";
import individualImageItem2 from "./individualImage2.jpg";
import individualImageItem3 from "./individualImage3.jpg";

// Import Item Images
import itemImage1 from "./item1.jpg";
import itemImage2 from "./item2.jpg";
import itemImage3 from "./item3.jpg";
// import Flip from "react-reveal/Flip";
import Roll from "react-reveal/Roll";
import Fade from "react-reveal/Fade";
import Pulse from "react-reveal/Pulse";

// Import Translation Module
import { useTranslation } from "react-i18next";

// ServiceMaintenance Component
function ServiceMaintenance() {
  const { t } = useTranslation("service-maintenance");

  return (
    <section id="service-maintenance" className="service-maintenance">
      <div className="header-wrapper">
        <header>
          <img
            src={productItem4}
            style={{ filter: "brightness(0.5)" }}
            alt="Products Background"
            className="header-background"
          />
          <Container className="header-inner d-flex justify-content-center">
            <Row className="d-flex justify-content-center">
              <Col lg="auto" className="d-flex flex-column align-items-center">
                {/* <img
                  src={servicesLogos}
                  alt="Logos"
                  className="service-logos"
                /> */}
                <Fade bottom>
                  <h1>{t("showcase-title")}</h1>
                </Fade>
                <Fade bottom>
                  <p>{t("showcase-text")}</p>
                </Fade>
                <Fade bottom>
                  <button>{t("showcase-button")}</button>
                </Fade>
              </Col>
            </Row>
          </Container>
        </header>
      </div>
      <main>
        <article className="needs">
          <Container>
            <Row className="d-flex justify-content-center">
              <Col lg="7" className="d-flex flex-column align-items-center">
                <Fade bottom>
                  <h2>{t("article-1-title")}</h2>
                </Fade>
                <Fade bottom>
                  <p>{t("article-1-text")}</p>
                </Fade>
                <Fade bottom>
                  <button>{t("article-1-button")}</button>
                </Fade>
              </Col>
            </Row>
          </Container>
        </article>
        <article className="equipment">
          <Container>
            <Row>
              <Col lg="6" className="equipment-items-container">
                <Fade bottom>
                  <h3>{t("article-2-title")}</h3>
                  <p>{t("article-2-text")}</p>
                  <div className="equipment-items-container-wrap">
                    <div className="equipment-item">
                      <img src={equipmentItem1} alt="Equipment Item" />
                      <h4>{t("article-2-equipment-1")}</h4>
                    </div>
                    <div className="equipment-item">
                      <img src={equipmentItem2} alt="Equipment Item" />
                      <h4>{t("article-2-equipment-2")}</h4>
                    </div>
                    <div className="equipment-item">
                      <img src={equipmentItem3} alt="Equipment Item" />
                      <h4>{t("article-2-equipment-3")}</h4>
                    </div>
                    <div className="equipment-item">
                      <img src={equipmentItem4} alt="Equipment Item" />
                      <h4>{t("article-2-equipment-4")}</h4>
                    </div>
                  </div>
                  <p>{t("article-2-equipment-bold")}</p>
                </Fade>
              </Col>
              <Col lg="6" className="equipment-image-container">
                <img
                  src={equipmentImageBackground}
                  alt={t("Equipemnt Image")}
                />
              </Col>
            </Row>
          </Container>
        </article>
        <div className="about-us-wrapper">
          <article className="about-us">
            <img
              src={aboutUsImageBackground}
              alt={t("About Us Image")}
              className="about-us-background-image"
            />
            <Container>
              <Row className="d-flex justify-content-center">
                <Col lg="7" className="d-flex align-items-center flex-column">
                  <Fade bottom>
                    <p>{t("article-3-suptitle")}</p>
                  </Fade>
                  <Fade bottom>
                    <h2>{t("article-3-title")}</h2>
                  </Fade>
                  <Fade bottom>
                    <p>{t("article-3-text")}</p>
                  </Fade>
                </Col>
              </Row>
            </Container>
          </article>
        </div>
        <article className="experience">
          <Container className="justify-content-center">
            <div
              className="d-flex  flex-column align-items-center"
              style={{ padding: "75px 0" }}
            >
              <Fade bottom>
                <h2 className="text-center">{t("article-4-title")}</h2>
              </Fade>
              <Fade bottom>
                <span className="d-block text-center text-md-left">
                  {t("article-4-text")}
                </span>
              </Fade>
            </div>
            <Fade bottom>
              <Row style={{ padding: "75px 0" }}>
                <Col
                  lg="6"
                  className="experience-item d-flex flex-column align-items-center"
                >
                  <img src={experienceItem1} alt={t("Experience Item One")} />
                  <h3>{t("article-4-card-1-title")}</h3>
                  <p>{t("article-4-card-1-text")}</p>
                </Col>
                <Col
                  lg="6"
                  className="experience-item d-flex flex-column align-items-center"
                >
                  <img src={experienceItem2} alt={t("Experience Item Two")} />
                  <h3>{t("article-4-card-2-title")}</h3>
                  <p>{t("article-4-card-2-text")}</p>
                </Col>
              </Row>
            </Fade>
            <Fade bottom>
              <Row
                className="d-flex align-items-center flex-row justify-content-between"
                style={{ padding: "75px 0" }}
              >
                <Col lg="6" className="maintenance-info-item">
                  <h3>{t("article-gallery-title")}</h3>
                  <p>{t("article-gallery-text-1")}</p>
                  <p>{t("article-gallery-text-2")}</p>
                </Col>
                <Col lg="6" className="maintenance-info-item">
                  <img
                    src={individualImageItem1}
                    alt={t("Maintenance Info Image")}
                  />
                </Col>
              </Row>
            </Fade>

            <Row
              className="d-flex flex-row justify-content-between"
              style={{ padding: "25px 0" }}
            >
              <Col
                lg="4"
                md="6"
                sm="12"
                className="service-info-item d-flex flex-column align-items-center mb-5"
              >
                <Pulse>
                  <div className="service-info-item-image-container">
                    <img src={itemImage1} alt={t("Item 1")} />
                  </div>
                  <div className="service-info-item-data-container">
                    <h3>{t("card-1-suptitle")}</h3>
                    <h4>{t("card-1-title")}</h4>
                    <p>{t("card-1-text")}</p>
                    <a href="#!">{t("card-1-button")}</a>
                  </div>
                </Pulse>
              </Col>
              <Col
                lg="4"
                md="6"
                sm="12"
                className="service-info-item d-flex flex-column align-items-center mb-5"
              >
                <Pulse>
                  <div className="service-info-item-image-container">
                    <img src={itemImage2} alt={t("Item 2")} />
                  </div>
                  <div className="service-info-item-data-container">
                    <h3>{t("card-2-suptitle")}</h3>
                    <h4>{t("card-2-title")}</h4>
                    <p>{t("card-2-text")}</p>
                    <a href="#!">{t("card-2-button")}</a>
                  </div>
                </Pulse>
              </Col>
              <Col
                lg="4"
                md="6"
                sm="12"
                className="service-info-item d-flex flex-column align-items-center mb-5"
              >
                <Pulse>
                  <div className="service-info-item-image-container">
                    <img src={itemImage3} alt={t("Item 3")} />
                  </div>
                  <div className="service-info-item-data-container">
                    <h3>{t("card-3-suptitle")}</h3>
                    <h4>{t("card-3-title")}</h4>
                    <p>{t("card-3-text")}</p>
                    <a href="#!">{t("card-3-button")}</a>
                  </div>
                </Pulse>
              </Col>
            </Row>
          </Container>
        </article>

        <article className="additional-information">
          <Container>
            <Row className="d-flex flex-row mb-lg-0 mb-5">
              <Col
                lg="6"
                className="additional-info-data-container d-flex align-items-lg-start text-center text-lg-start align-items-center flex-column mb-lg-0 mb-5"
              >
                <Fade bottom>
                  <h2>
                    {t("gallery-1-title")} <sup>®</sup>
                  </h2>
                  <h3>{t("gallery-1-subtitle")}</h3>
                  <p>{t("gallery-1-text")}</p>
                  <a href="#!">{t("gallery-1-button")}</a>
                </Fade>
              </Col>
              <Col
                lg="6"
                className="additional-info-image-container d-flex flex-column"
              >
                <img
                  src={individualImageItem2}
                  alt={t("Individual Image Item")}
                />
              </Col>
            </Row>
            <Row className="d-flex flex-row-reverse mb-lg-0 mb-5">
              <Col
                lg="6"
                className="additional-info-data-container d-flex text-center text-lg-start align-items-lg-start align-items-center flex-column mb-lg-0 mb-5"
              >
                <Fade bottom>
                  <h2>{t("gallery-2-title")}</h2>
                  <h3>{t("gallery-2-subtitle")}</h3>
                  <p>{t("gallery-2-text")}</p>
                  <a href="#!">{t("gallery-2-button")}</a>
                </Fade>
              </Col>
              <Col
                lg="6"
                className="additional-info-image-container d-flex align-items-start flex-column"
              >
                <img
                  src={individualImageItem3}
                  alt={t("Individual Image Item")}
                />
              </Col>
            </Row>
          </Container>
        </article>
        <article className="additional-part">
          <Container>
            <div className="additional-part-info d-flex text-md-start text-center flex-column align-items-center">
              <Fade bottom>
                <h1>{t("section-title")}</h1>
              </Fade>
              <Fade bottom>
                <p>{t("section-text")}</p>
              </Fade>
              <Fade bottom>
                <a href="#!">{t("section-button")}</a>
              </Fade>
            </div>
            <Row className="additional-part-links d-flex flex-wrap justify-content-between align-items-start flex-row">
              <AdditionalPart
                image={resourseImage1}
                title={t("section-card-1-title")}
              />
              <AdditionalPart
                image={resourseImage2}
                title={t("section-card-2-title")}
              />
            </Row>
          </Container>
        </article>
      </main>
    </section>
  );
}

export default ServiceMaintenance;
