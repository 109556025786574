import React from "react";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

const TextPage = () => {
  const { t } = useTranslation("modernization");
  return (
    <div className="TextPage">
      <div className="wrapper">
        <Fade bottom>
          <h1 className="title">{t("modernization-title")}</h1>
        </Fade>
        <br />
        <br />
        <Fade bottom>
          <p>{t("modernization-text")}</p>
        </Fade>
      </div>
    </div>
  );
};

export default TextPage;
