import React from "react";
import "./Modernization.style.scss";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
function Showcase() {
  const { t } = useTranslation("modernization");

  return (
    <div className="showcase">
      <div className="text-wrapper">
        <h1>{t("showcase-title")}</h1>
        <Link to="#">
          <button className="showcase-btn">{t("showcase-button")}</button>
        </Link>
      </div>
    </div>
  );
}

export default Showcase;
