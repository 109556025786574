import React from "react";
import "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";

function WhoWeAre() {
  const { t } = useTranslation("homepage");

  return (
    <div className="who-we-are">
      <div className="container justify-content-center px-5">
        <div className="home-text-wrapper">
          <Fade top>
            <p className="title">{t("we-are-suptitle")}</p>
          </Fade>
          <br />
          <Fade left>
            <h1>{t("we-are-title")}</h1>
          </Fade>
          <Fade right>
            <p>{t("we-are-subtitle")}</p>
          </Fade>
          <Fade left>
            <Link to="/aboutus" className="carousel-button">
              {t("we-are-button-1")}
            </Link>
          </Fade>
        </div>
        <div>
          <div className="statistics">
            <Fade right>
              <h1>{t("we-are-title-2")}</h1>
            </Fade>

            <Fade left>
              <div className="numbers">
                <div className="net-sales">
                  <h1>{t("left-number")}</h1>
                  <p className="statistics-description">{t("left-text")}</p>
                </div>

                <div className="units-serviced">
                  <h1>{t("middle-number")}</h1>
                  <p className="statistics-description">{t("middle-text")}</p>
                </div>

                <div className="people-moved-daily">
                  <h1>{t("right-number")}</h1>
                  <p className="statistics-description">{t("right-text")}</p>
                </div>
              </div>
            </Fade>
          </div>

          <Fade right>
            <div className="buttons">

            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default WhoWeAre;
