import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Showcase() {
  const { t } = useTranslation("career");

  return (
    <div className="CareersShowcase">
      <div className="text-wrapper">
        <h1 className="title">{t("showcase")}</h1>
        <br />
        <p>
          {t(
            "join"
          )}
        </p>
        <Link to="#">
          <button className="showcase-btn text-uppercase">{t("work-together")}</button>
        </Link>
        <Link to="#">
          <button className="showcase-btn text-uppercase mb-5">{t("contact")}</button>
        </Link>
      </div>
    </div>
  );
}

export default Showcase;
