import React from "react";
import { useTranslation } from "react-i18next";
import "./news.style.scss";
function BetaNews() {
  const { t } = useTranslation("news");
  return (
    <section className="news-section-2 ">
      <div className="news-section-2-text">
        <h1 className="title">{t("news-title")}</h1>
        <p className="news-section-2-p">{t("news-subtitle")}</p>
      </div>
    </section>
  );
}

export default BetaNews;
