import uz from "./locale/uz.json";
import ru from "./locale/ru.json";
import en from "./locale/en.json";
import tr from "./locale/tr.json";

const resources = {
  uz,
  ru,
  en,
  tr,
};

export default resources;
